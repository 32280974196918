import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment'
/**
 * deprecated
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class PixabayService {
/**
 * deprecated
 * @deprecated
 */
  constructor(private http: HttpClient) {

  }

/**
 * deprecated
 * @deprecated
 */
  searchForImage(searchString): Observable<any[]> {


    return this.http.get<any[]>(`https://pixabay.com/api?key=24605956-982d3aa6be88eb55a8816bbce&q=${searchString}&image_type=photo&per_page=120&order=popular&safesearch=true&editors_choice=true`);
  }
}
