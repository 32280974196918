import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PixabayService } from 'src/app/services/pixabay.service';
/**
 * @deprecated
 */
@Component({
  selector: 'app-stock-images-gallery',
  templateUrl: './stock-images-gallery.component.html',
  styleUrls: ['./stock-images-gallery.component.scss']
})
export class StockImagesGalleryComponent implements OnInit {
  /**
 * @deprecated
 */
  stockImages = [];
  /**
 * @deprecated
 */
  loadedImages: boolean = false;
  /**
 * @deprecated
 */
  searchStockImagesForm: UntypedFormGroup;
  /**
 * @deprecated
 * constructor
 */
  constructor(
    public dialogRef: MatDialogRef<StockImagesGalleryComponent>,
    private fb: UntypedFormBuilder,
    private pixabayService: PixabayService) { }



  /**
   * @deprecated
   */
  ngOnInit(): void {
    this.searchStockImagesForm = this.fb.group({
      searchSting: ['', [Validators.required]]
    })
  }
  /**
   * @deprecated
   */
  cancel(): void {
    this.dialogRef.close();
  }
  /**
 * @deprecated
 * Search for an Image
 */
  serachImagesViaEnter(keyEvent) {
    if (keyEvent.which === 13) {
      this.pixabayService.searchForImage(this.searchStockImagesForm.value.searchSting).subscribe((images: any) => {
        this.stockImages = images.hits
        this.loadedImages = true;
      })
    }
  }
  /**
 * @deprecated
 */
  serachImages() {
    this.pixabayService.searchForImage(this.searchStockImagesForm.value.searchSting).subscribe((images: any) => {
      this.stockImages = images.hits;
      this.loadedImages = true;
    })
  }
  /**
 * @deprecated
 */
  selectImage(image) {
    this.dialogRef.close(image);
  }
}
